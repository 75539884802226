<template>
  <div>
    <stripe-loader class="card-form">
      <div v-if="intentLoaded">
        <!-- Discount Banner -->
        <div class="card-form__banner text-center text-sm bg-orange-100 rounded-t-lg mx-30" v-if="formNotSubmitted && bannerText !== null">
          {{ bannerText }}
        </div>
        <div class="card-form__inner justify-content content-center flex flex-wrap mx-10" v-if="formNotSubmitted">
          <!-- STRIPE ELEMENTS -->
          <div class="payment-simple w-full">
            <StripeElements :stripe-key="pk_key" :instance-options="instanceOptions" :elements-options="elementsOptions" #default="{ elements }" ref="elms">
              <StripeElement type="payment" :elements="elements" @ready="isLoaded" ref="payment" class="md:w-11/12 md:mx-auto" />
            </StripeElements>
          </div>
          <button v-if="elementsLoaded" class="card-form__button" @click="updateCard" :disabled="isUpdating">
            <span v-if="isUpdating"><Loader /> Updating</span>
            <span v-else>{{ updateButtonLabel }}</span>
          </button>
        </div>
        <!-- Update Failed Message -->
        <div class="card-form__inner payment-message text-center" v-if="cardUpdateFailed">
          <div>
            <p class="text-xl text-brand-black font-semibold mb-8">{{ message.header }}</p>
            <p class="text-lg text-brand-black mt-4">{{ message.description }}</p>
          </div>
          <button class="card-form__button mt-15" @click="retry" type="button">Try Again</button>
        </div>
        <!-- Update Success Message -->
        <div class="card-form__inner payment-message text-center" v-if="cardUpdateSuccess">
          <div>
            <p class="text-xl text-brand-black font-semibold mb-8">{{ message.header }}</p>
            <p class="text-lg text-brand-black mt-4">{{ message.description }}</p>
          </div>
          <button class="card-form__button mt-15" @click="redirect" type="button">Continue to {{ companyName }}</button>
        </div>
        <!-- Append watermark -->
        <div v-if="showWatermark" class="mt-4">
          <Watermark message="Subscription Verified by Churnkey" product="pr" />
        </div>
      </div>
    </stripe-loader>
  </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
import StripeLoader from '@/components/StripeLoader.vue';
import Loader from '@/components/ButtonLoader.vue';
import Watermark from '@/components/Watermark.vue';

import appearance from '@/helpers/elements-config';
import * as Filters from '@/helpers/filters';
import EventBus from '@/helpers/event-bus';
import OrgService from '@/api/org.service';
import router from '@/router';

export default {
  name: 'StripeDemoForm',
  components: {
    StripeElements,
    StripeElement,
    StripeLoader,
    Loader,
    Watermark,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    updateButtonLabel() {
      return 'Update Card';
      // if (this.$store.state.account.ctaText !== undefined && this.$store.state.account.ctaText !== '') {
      // return this.$store.state.account.ctaText;
      // }
      // const offerCount = this.offers.length;
      // if (offerCount === 0) return 'Update Card';
      // if (offerCount === 1) return 'Update Card & Redeem Offer';
      // return 'Update Card & Redeem Offers';
    },
    bannerText() {
      if (this.futureDiscount && this.invoiceDiscount) {
        return 'Discounts applied to current and future payments';
      }
      if (this.futureDiscount) {
        const { coupon } = this.futureDiscount.displayDiscount;
        const showDuration = Filters.formatCouponDuration(coupon, 'for');
        const showAmount = Filters.formatCouponAmount(coupon);
        return `${showAmount} discount applied ${showDuration}`;
      }
      if (this.invoiceDiscount) {
        const { coupon } = this.invoiceDiscount.displayDiscount;
        const showAmount = Filters.formatCouponAmount(coupon);
        return `${showAmount} discount applied to your outstanding invoice`;
      }
      return null;
    },
    formNotSubmitted() {
      return !this.cardUpdateFailed && !this.cardUpdateComplete && !this.alreadyPaid;
    },
    cardUpdateSuccess() {
      return this.cardUpdateComplete && !this.cardUpdateFailed;
    },
    showWatermark() {
      return this.$store.state.account?.branding?.showWatermark && this.cardUpdateSuccess;
    },
    companyName() {
      return this.$store.state.account.name;
    },
    offers() {
      return this.$store.state.account.offers;
    },
    futureDiscount() {
      if (!this.offers || this.offers.length === 0) return null;
      return this.offers.find((o) => o.offerType === 'DISCOUNT');
    },
    invoiceDiscount() {
      if (!this.offers || this.offers.length === 0) return null;
      return this.offers.find((o) => o.offerType === 'INVOICE_DISCOUNT');
    },
    redirectLink() {
      let link = this.account.redirectLink;
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
  },
  data() {
    return {
      stripeLoaded: false,
      intentLoaded: false,
      elementsLoaded: false,
      cardUpdateComplete: false,
      cardUpdateFailed: false,
      isUpdating: false,
      message: {
        header: '',
        description: '',
      },
      pk_key: process.env.VUE_APP_STRIPE_TEST_KEY,
      instanceOptions: { locale: 'auto', stripeAccount: null },
      elementsOptions: { locale: 'auto', loader: 'auto', appearance },
    };
  },

  mounted() {
    // Load Data for Card Form
    console.log('StripeForm mounted', this.$store.state);
    this.instanceOptions.stripeAccount = this.account.stripeAccountId;
    this.getSetupIntent();
  },

  methods: {
    async retry() {
      // Adding the component back in
      this.cardUpdateFailed = false;

      // Set up again:
      this.intentLoaded = false;
      await this.getSetupIntent();
    },
    redirect() {
      // navigate to the org's website
      window.location.href = this.redirectLink;
    },
    isLoaded() {
      this.elementsLoaded = true;
    },

    // Configures Stripe by setting up the elements and creating the card element.
    async getSetupIntent() {
      try {
        const data = await OrgService.getDemoSetupIntent({ stripeAccountId: this.account.stripeAccountId });
        this.elementsOptions.clientSecret = data.client_secret;
        this.intentLoaded = true;
      } catch (err) {
        console.log('Error on getSetupIntent:', err.response?.data);
        this.$store.commit('SET_ERROR', { errorMessage: err.response?.data });
        // router.push('/error');
      }
    },

    async updateCard() {
      if (this.isUpdating) return; // Disable button while loading!
      this.isUpdating = true;
      const { elements, instance } = this.$refs.elms;

      // after 1 second, show complete
      setTimeout(() => {
        this.cardUpdateComplete = true;
        this.message.header = `You're all good.`;
        this.message.description = `We were able to charge your card on file. There's nothing you need to do for your account at this time.`;
        EventBus.$emit('invoicePaid');
      }, 1000);
    },
  },
  filters: {
    formatCouponDuration: Filters.formatCouponDuration,
  },
};
</script>
<style scoped>
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .card-form__inner {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .card-form__button {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.card-form__inner {
  padding-top: 40px;
  min-height: 350px;
}

.payment-message {
  padding-top: 40px;
  min-height: 300px;
}

@media only screen and (max-width: 480px) {
  .card-form__inner {
    padding-top: 0px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: 350px;
  }
}
</style>
